export const ICON_MAP = {
  AccessIcon: () =>
    import(`hugeicons-react/dist/esm/icons/access_icon`).then(
      (mod) => mod.default,
    ),
  AbsoluteIcon: () =>
    import(`hugeicons-react/dist/esm/icons/absolute_icon`).then(
      (mod) => mod.default,
    ),
  AbacusIcon: () =>
    import(`hugeicons-react/dist/esm/icons/abacus_icon`).then(
      (mod) => mod.default,
    ),
  AccidentIcon: () =>
    import(`hugeicons-react/dist/esm/icons/accident_icon`).then(
      (mod) => mod.default,
    ),
  AccelerationIcon: () =>
    import(`hugeicons-react/dist/esm/icons/acceleration_icon`).then(
      (mod) => mod.default,
    ),
  ArrowLeft01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/arrow_left_01_icon`).then(
      (mod) => mod.default,
    ),
  CapRoundIcon: () =>
    import(`hugeicons-react/dist/esm/icons/cap_round_icon`).then(
      (mod) => mod.default,
    ),
  City01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/city_01_icon`).then(
      (mod) => mod.default,
    ),
  City02Icon: () =>
    import(`hugeicons-react/dist/esm/icons/city_02_icon`).then(
      (mod) => mod.default,
    ),
  OfficeIcon: () =>
    import(`hugeicons-react/dist/esm/icons/office_icon`).then(
      (mod) => mod.default,
    ),
  StartUp02Icon: () =>
    import(`hugeicons-react/dist/esm/icons/start_up_02_icon`).then(
      (mod) => mod.default,
    ),
  Wrench01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/wrench_01_icon`).then(
      (mod) => mod.default,
    ),
  Rocket02Icon: () =>
    import(`hugeicons-react/dist/esm/icons/rocket_02_icon`).then(
      (mod) => mod.default,
    ),
  ArrowDown01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/arrow_down_01_icon`).then(
      (mod) => mod.default,
    ),
  BrushIcon: () =>
    import(`hugeicons-react/dist/esm/icons/brush_icon`).then(
      (mod) => mod.default,
    ),
  CodeIcon: () =>
    import(`hugeicons-react/dist/esm/icons/code_icon`).then(
      (mod) => mod.default,
    ),
  MobileProgramming01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/mobile_programming_01_icon`).then(
      (mod) => mod.default,
    ),
  ArrowRight01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/arrow_right_01_icon`).then(
      (mod) => mod.default,
    ),
  WebDesign01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/web_design_01_icon`).then(
      (mod) => mod.default,
    ),
  CloudIcon: () =>
    import(`hugeicons-react/dist/esm/icons/cloud_icon`).then(
      (mod) => mod.default,
    ),
  AnalyticsUpIcon: () =>
    import(`hugeicons-react/dist/esm/icons/analytics_up_icon`).then(
      (mod) => mod.default,
    ),
  Menu11Icon: () =>
    import(`hugeicons-react/dist/esm/icons/menu_11_icon`).then(
      (mod) => mod.default,
    ),
  Sun01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/sun_01_icon`).then(
      (mod) => mod.default,
    ),
  Cancel01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/cancel_01_icon`).then(
      (mod) => mod.default,
    ),
  PlusSignIcon: () =>
    import(`hugeicons-react/dist/esm/icons/plus_sign_icon`).then(
      (mod) => mod.default,
    ),
  MinusSignIcon: () =>
    import(`hugeicons-react/dist/esm/icons/minus_sign_icon`).then(
      (mod) => mod.default,
    ),
  LinkBackwardIcon: () =>
    import(`hugeicons-react/dist/esm/icons/link_backward_icon`).then(
      (mod) => mod.default,
    ),
  EyeIcon: () =>
    import(`hugeicons-react/dist/esm/icons/eye_icon`).then(
      (mod) => mod.default,
    ),
  UserIcon: () =>
    import(`hugeicons-react/dist/esm/icons/user_icon`).then(
      (mod) => mod.default,
    ),
  Home01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/home_01_icon`).then(
      (mod) => mod.default,
    ),
  Moon01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/moon_01_icon`).then(
      (mod) => mod.default,
    ),
  InstagramIcon: () =>
    import(`hugeicons-react/dist/esm/icons/instagram_icon`).then(
      (mod) => mod.default,
    ),
  MetaIcon: () =>
    import(`hugeicons-react/dist/esm/icons/meta_icon`).then(
      (mod) => mod.default,
    ),
  Activity02Icon: () =>
    import(`hugeicons-react/dist/esm/icons/activity_02_icon`).then(
      (mod) => mod.default,
    ),
  Tick01Icon: () =>
    import(`hugeicons-react/dist/esm/icons/tick_02_icon`).then(
      (mod) => mod.default,
    ),
} as const
