import NextjsLink from "next/link"
// import { useParams, useRouter } from "next/navigation"
import type { FC, CSSProperties } from "react"

import type { ReadonlyBaseLinkProps } from "./BaseLink.model"

/**
 * This is a generic unstyled link component that
 * - adds `target="_blank"` on external links
 * - adds `rel="noopener noreferrer"` on external links
 * - forces a plain link if the `download` attribute is present
 * - prepends the current non-default locale to `href` to support opening pages
 * in a new tab with the correct locale using middle-click or the context menu
 * - supports disabling a link by setting `disabled`
 * - ensures anchors lead to the expected page
 */
export const BaseLink: FC<ReadonlyBaseLinkProps> = ({
  ref,
  children,
  href,
  as,
  style = {},
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  disabled,
  ...htmlLinkProps
}) => {
  const DISABLE_STYLES: CSSProperties = { pointerEvents: "none" }
  const APP_URL_REG_EXP = /^\w+:/

  const nextjsLinkProps = {
    href,
    as,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    locale,
  }

  const isAbsolute = href?.startsWith("http")
  // const isHash = href.startsWith("#")

  // Next.js ignores the download attribute and opens the file instead of
  // downloading it. To force a download we need a regular HTML link.
  const isDownload = !!htmlLinkProps.download

  // e.g. `mailto`, `tel`
  const isAppUrl = APP_URL_REG_EXP.test(href)

  // External links should be opened in a new tab and the target page should
  // not have access to the opener window.
  // https://web.dev/external-anchors-use-rel-noopener
  const target = htmlLinkProps.target || (isAbsolute ? "_blank" : undefined)
  const rel =
    htmlLinkProps.rel || (isAbsolute ? "noopener noreferrer" : undefined)

  // Support disabling links by disabling focus and click.
  const linkStyle = disabled ? { ...DISABLE_STYLES, ...style } : style
  const tabIndex = disabled ? -1 : undefined

  // Next.js only prepends the locale when LEFT-clicking the link.
  // If the link is opened in a new tab, the locale is NOT taken into account.
  // const router = useRouter()
  // if (
  //   !isAbsolute &&
  //   !isHash &&
  //   !isDownload &&
  //   !isAppUrl &&
  //   ((!!locale && locale !== router.defaultLocale) ||
  //     (!locale && router.locale !== router.defaultLocale))
  // ) {
  //   const targetLocale = locale || router.locale
  //   if (!href.startsWith(`/${router.locale}/`)) {
  //     href = "/" + targetLocale + href
  //   }
  // }

  if (isAbsolute || isDownload || isAppUrl) {
    return (
      <a
        {...htmlLinkProps}
        ref={ref}
        href={href}
        rel={rel}
        style={linkStyle}
        tabIndex={tabIndex}
        target={target}
      >
        {children}
      </a>
    )
  }
  return (
    <NextjsLink
      {...nextjsLinkProps}
      {...htmlLinkProps}
      ref={ref}
      href={href}
      locale={locale}
      style={linkStyle}
      tabIndex={tabIndex}
    >
      {children}
    </NextjsLink>
  )
}

BaseLink.displayName = "BaseLink"
