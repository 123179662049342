"use client"
import { useTheme } from "@mui/material/styles"
import dynamic from "next/dynamic"
import { type FC, type HTMLAttributes, useCallback, useMemo } from "react"
import remarkBreaks from "remark-breaks"
import remarkGfm from "remark-gfm"

import type { HeadlineProps, HeadlineVariant } from "./Headline.model"
import styles from "./Headline.module.scss"

import { AnimatedText } from "@/components/common/animatedText"

import { create } from "@/helpers/bem"

const bem = create(styles, "Headline")

const Markdown = dynamic(() => import("react-markdown"), {
  ssr: false,
})

export const POSSIBLE_HIGHLIGHTS = [
  "inherit",
  "primary",
  "secondary",
  "white",
] as HeadlineVariant[]

export const Headline: FC<HeadlineProps> = ({
  animated,
  animationProps = {},
  type = "h3",
  size = "md",
  highlight,
  schema = {},
  className,
  lineClamp,
  align,
  isRichtext = false,
  variant = "inherit",
  ...props
}) => {
  const theme = useTheme()
  const isHighlighted = !!highlight && POSSIBLE_HIGHLIGHTS.includes(variant)
  const modifier = {
    [`${theme.palette.mode}--${variant}`]: !!variant,
    "is-highlighted": isHighlighted,
    [`${theme.palette.mode}--highlighted`]: isHighlighted,
    [`${theme.palette.mode}--${variant}--highlighted`]: isHighlighted,
    [size]: typeof size === "string" && !!size,
    [`${align}`]: !!align,
  }
  const bemClass = bem(undefined, modifier, className)
  const customStyle = useMemo(() => {
    if (typeof size === "number")
      return {
        fontSize: `${size}em`,
      }
    return {}
  }, [size])

  const baseProps = useMemo(
    () => ({
      "aria-label": props?.children,
      className: bemClass,
      style: customStyle,
      node: undefined,
      ...schema,
    }),
    [bemClass, customStyle, schema, props?.children],
  )
  const animateText = useCallback(
    (children: string) => {
      if (!animated) return children
      return <AnimatedText {...animationProps}>{children}</AnimatedText>
    },
    [animated, animationProps],
  )
  const renderHTag = useCallback(
    (attr: any) => {
      switch (type) {
        case "h1":
          return (
            <h1 {...attr} aria-level="1" {...baseProps}>
              {animateText(attr.children)}
            </h1>
          )
        case "h2":
          return (
            <h2 {...attr} aria-level="2" {...baseProps}>
              {animateText(attr.children)}
            </h2>
          )
        case "h3":
          return (
            <h3 {...attr} aria-level="3" {...baseProps}>
              {animateText(attr.children)}
            </h3>
          )
        case "h4":
          return (
            <h4 {...attr} aria-level="4" {...baseProps}>
              {animateText(attr.children)}
            </h4>
          )
        case "h5":
          return (
            <h5 {...attr} aria-level="5" {...baseProps}>
              {animateText(attr.children)}
            </h5>
          )
        case "h6":
          return (
            <h6 {...attr} aria-level="6" {...baseProps}>
              {animateText(attr.children)}
            </h6>
          )
      }
    },
    [type, baseProps, animateText],
  )
  if (isRichtext) {
    return (
      <Markdown
        className={bem("headline")}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          p: (props: HTMLAttributes<HTMLParagraphElement>) => renderHTag(props),
        }}
      >
        {props?.children as string}
      </Markdown>
    )
  }
  return renderHTag(props)
}
