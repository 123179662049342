"use client"
import MatButton from "@mui/material/Button"
import { useTheme } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { type FC, type ReactNode, useRef } from "react"

import { Icon, getIconSize } from "../icon"
import { Link } from "../link"
import { Loading } from "../loading"

import { ButtonProps, type ButtonDefaultProps } from "./Button.model"
import styles from "./Button.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Button")

export const Button: FC<ButtonProps> = ({
  fullWidth,
  color = "primary",
  iconProps = {},
  loading,
  variant = "contained",
  className,
  contentClassName,
  disabled,
  redirect,
  ...props
}) => {
  const theme = useTheme()
  const labelRef = useRef<HTMLSpanElement | null>(null)
  const isOutlined = variant === "outlined"
  const isText = variant === "text"
  const iconName = iconProps?.name
  const isIconOnly = iconName && !(props as ButtonDefaultProps)?.title
  const contentModifier = {
    [theme.palette.mode]: true,
    "icon-only": !!isIconOnly,
  }

  const renderInnerContent = () => (
    <span ref={labelRef} className={bem("title")}>
      {iconName && (
        <Icon
          className={bem("icon", {
            "icon-only": !!isIconOnly,
          })}
          {...iconProps}
        />
      )}
      {(props as ButtonDefaultProps)?.title}
    </span>
  )

  const renderContent = () => {
    if (loading) {
      return <Loading size="xs" />
    }
    return renderInnerContent()
  }

  const renderLink = (children: ReactNode) =>
    redirect !== undefined ? (
      <Link
        hasBackground
        disabled={disabled}
        variant={variant}
        className={bem("link", {
          [`${theme.palette.mode}--${color}`]: true,
          "is-not-outlined": variant !== "outlined",
          "has-icon-only": !!isIconOnly,
        })}
        {...redirect}
      >
        {children}
      </Link>
    ) : (
      children
    )

  const renderVariant = () => {
    if (isOutlined) {
      return (
        <div className={bem("content", contentModifier, contentClassName)}>
          {renderContent()}
        </div>
      )
    }
    return renderContent()
  }

  const labelWidth = labelRef?.current?.offsetWidth
  const width = labelWidth ? labelWidth + 30 : "auto"
  const iconSize = isIconOnly && getIconSize(iconProps?.size)
  const sx = {
    minWidth: !isIconOnly && !redirect ? width : "auto",
    width: iconSize ? iconSize + 12 : "auto",
    height: iconSize ? iconSize + 12 : "auto",
    padding: redirect ? 0 : !isIconOnly ? "0.63rem 1.5rem" : "1.5rem",
    borderRadius: !isIconOnly ? "6.25rem" : "10000px",
    textTransform: "none",
    boxShadow: theme.shadows[1],
    background: theme.gradiant?.[color],
    ...(isOutlined && {
      minWidth: !isIconOnly ? width : (iconSize ?? "auto"),
      width: "auto",
      height: iconSize ? iconSize + 20 : "auto",
      borderWidth: 0,
      padding: "0.125rem",
      borderRadius: !isIconOnly ? "6.25rem" : "10000px",
      color: theme.palette.inherit.main,
    }),
    ...(isText && {
      background: theme.palette.common?.white,
    }),
    ...(isOutlined &&
      color === "warning" && {
        color: theme.palette.warning.main,
      }),
    ...(isOutlined &&
      color === "error" && {
        color: theme.palette.error.main,
      }),
    ":hover": {
      ...(isOutlined && {
        borderWidth: 0,
      }),
    },
  } as SxProps
  return (
    <MatButton
      {...props}
      color={color}
      disabled={disabled || loading}
      fullWidth={fullWidth}
      sx={sx}
      variant={variant}
      className={bem(
        undefined,
        {
          "has-fullWidth": !!fullWidth,
        },
        className,
      )}
      tabIndex={
        redirect !== undefined || disabled ? -1 : (props?.tabIndex ?? undefined)
      }
    >
      {renderLink(renderVariant())}
    </MatButton>
  )
}
