import dynamic from "next/dynamic"
import { type FC, Suspense, memo } from "react"

import type { IconSize, IconProps, IconName } from "./Icon.model"
import { ICON_MAP } from "./Icon.utils"

const FallbackIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" />
    <line stroke="black" strokeWidth="2" x1="6" x2="18" y1="6" y2="18" />
  </svg>
)

export const getIconSize = (size?: IconSize) => {
  switch (size) {
    case "xs":
      return 15
    case "sm":
      return 20
    case "md":
      return 30
    case "lg":
      return 40
    case "xl":
      return 50
    default:
      return size ?? 16
  }
}

const LazyIconComponent = (iconName: IconName) =>
  dynamic(() =>
    ICON_MAP[iconName]
      ? ICON_MAP[iconName]()
      : Promise.resolve(() => <FallbackIcon />),
  )

export const Icon: FC<IconProps> = memo(({ name, size, ...props }) => {
  if (!name) {
    return null
  }
  const Component = LazyIconComponent(name) as FC<IconProps>
  if (!Component) {
    return null
  }
  return (
    <Suspense fallback={<FallbackIcon />}>
      <Component
        aria-hidden="true"
        role="presentation"
        size={getIconSize(size)}
        {...props}
      />
    </Suspense>
  )
})

Icon.displayName = "Icon"
